import React from 'react'
import {graphql, Link} from 'gatsby'
import Layout from '../components/layout.js'
import SEO from '../components/seo.js'


const AboutPage = props => (
  <Layout>
    <div className="page">
      <SEO keywords={[`blog`, `gatsby`, `prismic`]} />

    <section className="pt-20 lg:pt-32 header px-6 text-sm">
                <div className="flex flex-wrap mx-auto items-center mb-8">
                            <h2 className="text-gray-300 mr-4 font-semibold">
                                <Link to="/">
                                Homepage</Link>
                            </h2>

                            <h2 className="text-gray-400 font-normal">
                                About Us
                            </h2>

                            </div>
            </section>



      <section className="px-6">
          <div className="content-header-container mx-auto pt-8 pb-10 ">
          <h1 className="text-4xl font-bold mb-2 text-pink-600 pb-4">About us</h1>
              <div
                className="c-article-content leading-8 article-paragraph text-gray-300"
                dangerouslySetInnerHTML={{ __html: props.data.prismicAbout.data.about_text.html }}
              />

          </div>
      </section>

        <section className="content-header-container mx-auto mt-8 lg:px-0 px-6">
                    <div className="rounded-lg text-gray-300 shadow-lg bg-gray-800 h-44 flex justify-center items-center flex-col">

                            <span className="lg:mb-4 text-lg lg:text-xl lg:p-0 p-4 flex text-center">Get an overview of the Baltic tech scene straight to your inbox</span>
                            <Link to="/subscription/">
                                <button className="bg-pink-600 rounded py-1 px-2">
                                    Subscribe
                                </button>
                            </Link>
                    </div>
                </section>


    </div>
  </Layout>
);

export default AboutPage

export const AboutQuery = graphql`
  query About {
  prismicAbout {
    data {
      about_text {
        html
      }
    }
  }
}



`